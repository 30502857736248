import React from 'react';
import { Poker } from '../../components/Poker/Poker';
import './GamePage.css';

export const GamePage = () => {
  return (
    <div>
      <Poker />
    </div>
  );
};
